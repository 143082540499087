import {
  type AutosSurcharges,
  type GetListingQuery,
  type ListingPriceType,
} from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { type FC, type HTMLAttributeAnchorTarget } from 'react'
import { useTheme } from 'styled-components'

import { ListingImageWithFallback } from '@/components/shared/core-listing-card/card-details/ListingImageWithFallback'
import {
  CoreListingCardAttributes,
  CoreListingCardBody,
  CoreListingCardContainer,
  CoreListingCardImageContainer,
  CoreListingCardTitle,
} from '@/components/shared/core-listing-card/styled'
import { formatListingDetails } from '@/components/shared/core-listing-card/utils/formatListingDetails'
import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { FavouriteButton } from '@/components/shared/listing/FavouriteButton'
import { ListingSurcharges } from '@/components/shared/listing-surcharges/ListingSurcharges'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { isAutosDealerAmountPrice } from '@/domain/listings/AutosDealerAmountPrice'
import { getListingPrice } from '@/domain/listings/getListingPrice'
import { useLocale } from '@/hooks/useLocale'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'

type Listing = NonNullable<GetListingQuery['listing']>

/**
 * This type definition allows any listing query to use this carousel if the
 * correct listing fields are present in their query / prevents over-fetching to use this component
 */
export type CoreListingCardData = Pick<
  Listing,
  'categoryId' | 'id' | 'imageUrls' | 'title' | 'url'
> & {
  location: Pick<Listing['location'], 'name' | 'id'>
  price?: { type: ListingPriceType; amount?: number; surcharges?: AutosSurcharges } | null
  attributes?: Listing['attributes']
  isPlaceholder?: boolean
}

export type CoreListingCardProps = {
  /** Listing index */
  index: number
  /**
   * Allow favourite button to appear
   * When true, then listingId is required
   */
  allowFavourite?: boolean
  /**
   * Organic listing returned from ANVIL
   */
  listing: CoreListingCardData
  /**
   * Target attribute for the listing link
   */
  linkTarget?: HTMLAttributeAnchorTarget
  /**
   * Function triggered on card click
   */
  onClick?: (position: number, listingId: string) => void
}

/**
 * This component is the Core Vertical Listing card commonly used on carousels
 * to display the core content of a listing.
 */
export const CoreListingCard: FC<CoreListingCardProps> = ({
  index,
  allowFavourite,
  linkTarget = '_blank',
  listing,
  onClick,
}) => {
  const { url, imageUrls, title, id, isPlaceholder } = listing

  const { t } = useTranslation(TRANSLATION_KEYS.LISTING)
  const { colors, spacing } = useTheme()
  const { routeLocale } = useLocale()

  const listingDetails = !isPlaceholder ? formatListingDetails(listing, t) : null

  const formattedPrice = !isPlaceholder
    ? getListingPrice({ price: listing.price, routeLocale, t })
    : null
  const surcharges = isAutosDealerAmountPrice(listing.price) ? listing.price.surcharges : undefined

  const handleListingClick = () => {
    const listingPosition = index + 1
    onClick?.(listingPosition, listing.id)
  }

  return (
    <CoreListingCardContainer>
      <LinkCustom href={url} noStyle onClick={handleListingClick} target={linkTarget}>
        <CoreListingCardImageContainer>
          <ListingImageWithFallback src={imageUrls[0]} alt="" />
        </CoreListingCardImageContainer>

        <CoreListingCardBody>
          {/* This component will ensure the title is capped at 2 lines on desktop and 1 line on mobile */}
          <CoreListingCardTitle forwardedAs="h3" color={colors.grey.primary} size="medium">
            {title}
          </CoreListingCardTitle>

          <Flex flexDirection="column" gap={spacing.mini}>
            {listingDetails ? (
              // This component will ensure the attribute text is capped in 1 line
              <CoreListingCardAttributes color={colors.grey.light1} size="small">
                {listingDetails}
              </CoreListingCardAttributes>
            ) : null}

            <Flex justifyContent="space-between" gap={spacing.mini}>
              <Flex alignItems="center" gap={spacing.mini}>
                <BodyText color={colors.grey.primary} size="medium" weight="bold">
                  {formattedPrice}
                </BodyText>
                <ListingSurcharges surcharges={surcharges} />
              </Flex>

              {!isPlaceholder && allowFavourite ? <FavouriteButton listingId={id} /> : null}
            </Flex>
          </Flex>
        </CoreListingCardBody>
      </LinkCustom>
    </CoreListingCardContainer>
  )
}
